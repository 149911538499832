<template>
  <div>
    <isHeader ref="isHeader" />
    <div
      ref="scrollBox"
      style="height: 100vh; overflow-y: auto; overflow-x: hidden"
    >
      <commonTop
        :info="{
          title: '智能监测设备',
          desc: '华信互联与硬件生态伙伴深度合作，为慢病患者打造了多款智能化的健康监测设备，赋能旗下全套解决方案以及产品服务。',
        }"
        :img="require('@/assets/IntelligentMonitoring/banner.png')"
      >
        <!-- 插槽 -->
        <!-- <button>在线咨询</button> -->
        <button @click="$store.commit('changeAppointModal', true)">
          预约体验
        </button>
      </commonTop>
      <!--  -->
      <isTab current="智能监测设备" :info="info" />
      <isCom />
      <isFooter />
    </div>
  </div>
</template>

<script>
import isHeader from "@/components/header/index.vue";
import isFooter from "@/components/footer/index.vue";
import commonTop from "@/components/commonTop/index.vue";
import isTab from "@/components/ProductTab/tab.vue";
import isCom from "@/components/IntelligentMonitoring/com.vue";
export default {
  name: "IntelligentMonitoring",
  components: {
    isCom,
    commonTop,
    isHeader,
    isFooter,
    isTab,
  },
  data() {
    return {
      info: {
        partOne: {
          title: "产品设计理念",
          sub: "深入分析传统医疗数据采集痛点  为体征监测提供数字化产品解决方案",
          swiper: [],
          img: require("@/assets/IntelligentMonitoring/img.png"),
        },
        partTwo: {
          title: "产品功能介绍",
          sub: "聚焦慢病服务  携手医院深度共建多层次的慢病服务体系",
          list: [
            {
              title: "智能体测终端",
              desc: "双用户设计，与云端实时互联，无时不在的智能血压监控以及智能预警，为高血压患者健康保驾护航。",
            },
          ],
          right_img: require("@/assets/IntelligentMonitoring/swiper_right_img.png"),
        },
      },
    };
  },
  mounted() {
    let that = this;
    this.$refs.scrollBox.addEventListener("scroll", function (e) {
      if (e.target.scrollTop > 80) {
        that.$refs.isHeader.setScrollBg(true);
      } else {
        that.$refs.isHeader.setScrollBg(false);
      }
    });
  },
};
</script>

<style>
</style>